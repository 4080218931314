import { useState, useEffect, useMemo, useRef } from 'react';
import {
  millisecondsToSeconds,
  secondsToMilliseconds,
  intervalToDuration,
} from 'date-fns';
import { LotteryDetail } from 'lib/types/lottery';

const UPDATE_INTERVAL = 1000;

export function findNextDrawingTime(
  futureDrawingTimes: number[],
  cutoffSeconds: number,
) {
  const now = millisecondsToSeconds(Date.now());
  const nextDrawingTime = futureDrawingTimes.find(
    (drawing_time) => drawing_time - cutoffSeconds > now,
  )!;

  return nextDrawingTime;
}

const getTimesForLottery = (lottery: LotteryDetail, cutoffSeconds: number) => {
  const nextDrawingTime = findNextDrawingTime(
    lottery.next_drawings,
    cutoffSeconds,
  );

  return {
    cutoffSeconds,
    nextCutoffTime: nextDrawingTime - cutoffSeconds,
    nextDrawingTime,
  };
};

export const getJackpocketTimesForLottery = (lottery: LotteryDetail) => {
  const { jackpocket_cutoff_seconds } = lottery;

  return getTimesForLottery(lottery, jackpocket_cutoff_seconds);
};

export const getOfficialTimesForLottery = (lottery: LotteryDetail) => {
  const { official_cutoff_seconds } = lottery;

  return getTimesForLottery(lottery, official_cutoff_seconds);
};

function findNextCountdown(nextCutoffTime: number) {
  const countDown = intervalToDuration({
    start: new Date().getTime(),
    end: secondsToMilliseconds(nextCutoffTime),
  });

  return countDown;
}

export default function useGameTimes(lottery: LotteryDetail) {
  const { nextCutoffTime } = useMemo(
    () => getJackpocketTimesForLottery(lottery),
    [lottery],
  );

  const [countDown, setCountDown] = useState(findNextCountdown(nextCutoffTime));

  const countDownRef = useRef<any>();

  useEffect(() => {
    countDownRef.current = setInterval(() => {
      setCountDown(findNextCountdown(nextCutoffTime));
    }, UPDATE_INTERVAL);

    return () => clearInterval(countDownRef.current);
  }, [nextCutoffTime]);

  return { countDown };
}

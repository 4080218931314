import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { rem } from 'polished';
import theme from 'lib/styles/theme';
import { ButtonBase } from 'components/atoms/buttons';
import { Item, Content, Trigger } from '@radix-ui/react-accordion';
import { FiChevronDown } from 'react-icons/fi';
import media from 'lib/styles/media';

const { colors, font } = theme;

export const Page = styled.div`
  height: 100%;
  background: linear-gradient(
    180deg,
    ${colors.gray_neutral} 50%,
    ${colors.white} 100%
  );
`;

export const Hero = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 30px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-color: ${colors.jackpocketBlue};
  color: ${colors.white};
  overflow: hidden;
  object-fit: cover;

  > svg {
    max-width: 150px;
    margin-bottom: 50px;
    flex-shrink: 0;
  }
`;

export const HeroCenter = styled.div`
  width: 100%;
  min-height: 350px;
  max-width: 1080px;
  padding-bottom: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  > h1 {
    font-family: ${font.display};
    color: ${colors.white};
    font-size: ${rem(36)};
    text-align: center;
    max-width: 340px;
  }

  > h2 {
    font-weight: 500;
    font-size: ${rem(18)};
    line-height: 28px;
    margin-top: 0;
    max-width: 340px;
    padding: 0 8px;
    text-align: center;
  }

  ${media.medium`
    > h1 {
      font-size: ${rem(40)};
    }

    > h2 {
      font-size: ${rem(20)};
    }
  `}
`;

export const StartButton = styled.button`
  ${ButtonBase};
  width: 145px;
  height: 51px;
  background-color: ${colors.white};
  box-shadow: 0 4px 8px rgb(0 0 0 / 0.1);
  border-radius: 12px;
  color: ${colors.charcoal};
  font-family: ${theme.font.default};
  font-size: ${rem(16)};
  z-index: 1;
`;

export const LotteryBallsLeft = styled.div`
  display: none;
  max-height: 238px;
  position: absolute;
  top: 75px;
  left: -116px;
  margin: 0;
  object-fit: contain;

  ${media.medium`
  max-height: 320px;
  top: -3px !important;
  left: -60px;
  `}
  ${media.large`
display:block;
  top: -80px !important;
  max-height: 450px;
  left: 40px;
  `}
`;

export const LotteryBallsRight = styled.div`
  display: none;
  max-height: 80px;
  position: absolute;
  top: -120px;
  right: -10px;
  margin: 0;
  object-fit: contain;
  ${media.medium`
top: -130px;
max-height: 118px;
right: -9px;
  `}
  ${media.large`
  display:block;
  top: -130px;
  max-height: 150px;
  right: 35px;
  `}
`;

export const HalfToneRight = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    mix-blend-mode: overlay;
  }
  ${media.large`
 display:block
 `}
`;

export const HalfToneLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: -150px;

  img {
    mix-blend-mode: overlay;
  }
  ${media.mlarge`
   left:0;
   img{
   display: block;
   }
  `}
`;

export const HalfToneCtaLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: -320px;

  img {
    mix-blend-mode: overlay;
  }
  ${media.mlarge`
  left: 0px;
  `}
`;

export const GameCards = styled.div`
  position: relative;
  padding: 20px;
  display: grid;
  grid-template-columns: minmax(320px, 500px);
  gap: 16px;
  justify-content: center;
  margin-top: -100px;
  z-index: 1;

  ${media.mlarge`
    grid-template-columns: repeat(2, minmax(320px, 400px));
  `}
`;

export const SectionTitle = styled.h1`
  margin: 40px auto;
  font-family: ${font.display};
  color: ${colors.jackpocketBlue};
  font-size: ${rem(26)};
  text-align: center;
  ${media.medium`
 font-size: ${rem(36)};
  `}
`;

export const HowItWorks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  justify-content: center;
  padding: 0 20px;
  color: ${colors.text};
  text-align: center;

  ${media.mlarge`
    gap: 40px;
    grid-template-columns: repeat(3, 240px);
  `}

  ${media.large`
    grid-template-columns: repeat(3, 285px);
  `}
`;

export const HiwBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: left;

  > div {
    flex-shrink: 0;
  }

  > p {
    margin-left: 20px;
    font-size: ${rem(16)};
    line-height: 22px;
  }
  ${media.medium`
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;

    > p {
      margin-left: 0;
    }
   `}
`;

export const PrizeWrap = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 177px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  background-color: ${colors.jackpocketBlue};
  color: ${colors.white};
  text-align: center;
  font-family: ${theme.font.display};

  h1 {
    margin: 0;
    font-size: ${rem(45)};
    color: ${colors.white};
  }

  h3 {
    margin: 0;
    font-size: ${rem(24)};
    color: ${colors.white};
  }
  ${media.medium`
    > h1 {
    margin: 0;
    font-size: ${rem(64)};
    color: ${colors.white};
  }
  > h3 {
    margin: 0;
    font-size: ${rem(24)};
    color: ${colors.white};
  }
    `}
`;

export const CtaBar = styled(PrizeWrap)`
  position: relative;
  flex-direction: column;
  margin: 0;
  overflow: hidden;

  > div {
    z-index: 1;
  }

  h1 {
    font-size: ${rem(36)};
    margin-bottom: 22px;
  }
`;

export const FaqWrap = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
  color: ${colors.text};

  h3 {
    font-size: ${rem(16)};
    margin-left: 0;
  }
`;

export const FaqItem = styled(Item)`
  border-bottom: 1px solid ${colors.border};

  > h3 {
    margin: 0;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${rem(20)};

    &[data-state='open'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const FaqTrigger = styled(Trigger)`
  padding: 18px 0;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const FaqContent = styled(Content)`
  color: ${colors.gray_medium};

  > p {
    font-size: ${rem(16)};
    line-height: 22px;
  }
`;

export const AccordionChevron = styled(FiChevronDown).attrs({
  color: colors.gray_medium,
})``;

export const MediaSection = styled.div`
  width: 100%;
  margin-top: 90px;
  padding: 34px 20px;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray_neutral};

  > a {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    > img {
      max-height: 65px;
      max-width: 115px;
    }

    &:last-of-type {
      grid-column: 1/-1;
    }
  }

  ${media.mlarge`
    height: 240px;
    grid-template-columns: repeat(5, 125px);
    gap: 0 32px;

    > a:last-of-type {
      grid-column: 5;
    }
  `}
`;

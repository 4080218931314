import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';
import media from 'lib/styles/media';
import { motion } from 'framer-motion';

const { colors } = theme;

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  background: rgb(0 0 0 / 0.7);
  z-index: 2;
`;

export const ModalContainer = styled(motion.div)`
  width: 90%;
  max-height: 90%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 0.6);
  overflow: hidden;
  ${media.medium`
  width: 60%;
  `}
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grayish_blue};
    border: solid ${colors.gray_pale};
    border-radius: 4px;
    border-width: 16px 2px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${colors.gray_pale};
  }
`;

export const Header = styled.div`
  width: 100%;
`;

export const CloseButton = styled.button`
  padding: 0 6px 6px 0;
  width: 26px;
  height: 26px;
  ${media.medium`
    padding: 0 8px 8px 0;
    width: 28px;
    height: 28px;
  `}
`;

export const PromptWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.35;
  color: ${colors.charcoal};

  > h2 {
    font-size: ${rem(24)};
    text-align: center;
    margin: 0 0 16px;
    padding: 0 6px;
  }

  > p {
    text-align: center;
    max-width: 380px;
    margin: 0;
    font-size: ${rem(16)};
    padding: 0 6px;

    > a {
      text-decoration: none;
    }
  }

  > form {
    width: 100%;
  }
  ${media.medium`
    h2, p {
      padding: 0 8px;
    }
  `}
`;

export const IconTop = styled.img`
  max-width: 80px;
`;

export const ButtonRow = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;

  a {
    text-decoration: none;
    color: ${colors.white};
  }

  button {
    width: 100%;
    height: 45px;
  }
`;

export const StickyHeader = styled(motion.div)`
  align-items: center;
  background-color: ${colors.white};
  box-shadow: 0 4px 8px rgb(0 0 0 / 0.08), inset 0 -1px 0 ${colors.grayish_blue};
  height: 56px;
  left: 0;
  padding: 18px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  ${CloseButton} {
    bottom: 13px;
    left: 12px;
    padding: 0;
    position: absolute;
  }
`;

import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useAtom } from 'jotai';
import useModal from 'components/hooks/use-modal';
import { useUser } from 'components/hooks/use-signup-query';
import {
  playOptionsState,
  gameIdAtom,
  useGameLocation,
} from 'lib/atomic-state';
import { useTrackingClient } from 'lib/tracking';
import { LotteryDetail } from 'lib/types/lottery';

import { DateFormat } from 'components/atoms/format-time';
import LiveGameDetails from './live-game-details';
import {
  CardWrapper,
  Card,
  JackPocketSum,
  MetaText,
  GameHeader,
} from './styles';

const GameNameSymbol = dynamic(
  () => import('components/atoms/game-name-symbol'),
  {
    ssr: false,
  },
);

const FormatTime = dynamic(() => import('components/atoms/format-time'), {
  ssr: false,
});

const PlayCardButton = dynamic(() => import('./play-card-button'), {
  ssr: false,
});

type Props = {
  game: LotteryDetail;
  withLiveGameDetails?: boolean;
  locationKey?: string;
};

export const GameCard = ({ game, withLiveGameDetails, locationKey }: Props) => {
  const {
    name,
    id,
    next_jackpot_display,
    primary_color,
    next_drawing_timestamp,
  } = game;

  const router = useRouter();
  const { user } = useUser();
  const trackingClient = useTrackingClient();
  const modal = useModal();

  const { gameLocation } = useGameLocation();
  const [playRequest, setPlayRequest] = useAtom(playOptionsState);
  const [, setCurrentGameId] = useAtom(gameIdAtom);

  const handleClickPlay = () => {
    if (!game.active) {
      modal.show({
        message:
          game.inactive_message ||
          'This game is currently unavailable to play.',
        onProceed: modal.hide,
      });

      return;
    }

    setCurrentGameId(id);

    if (playRequest) {
      setPlayRequest(null);
    }

    if (user) {
      router.push(`/play`);
    } else {
      router.push(`/auth/signup`);
    }

    trackingClient.trackEventForPrimaryTrackers('Play Clicked', {
      lottery_id: id,
      lottery_name: name,
      source: router.pathname,
    });
  };
  const withSymbolLocationKey = locationKey || gameLocation?.value;

  return (
    <CardWrapper>
      <Card color={primary_color}>
        <GameHeader>
          <h2 data-testid={`game_card_${id}_name`}>
            <GameNameSymbol
              gameName={name}
              locationKey={withSymbolLocationKey}
            />
          </h2>
          <JackPocketSum data-testid={`game_card_${id}_jackpot`}>
            {next_jackpot_display}
          </JackPocketSum>
        </GameHeader>
        <div>
          {withLiveGameDetails ? (
            <LiveGameDetails game={game}>
              <PlayCardButton
                handleClickPlay={handleClickPlay}
                primaryColor={primary_color}
                gameId={game.id}
                locationKey={gameLocation?.value}
                isMuted={!game.active}
              />
            </LiveGameDetails>
          ) : (
            <>
              <MetaText>
                <FormatTime
                  label="Drawing"
                  timestamp={next_drawing_timestamp}
                  dateFormat={DateFormat.dateShort}
                  dataTestId={`game_card_${id}_drawing`}
                />
              </MetaText>
              <PlayCardButton
                handleClickPlay={handleClickPlay}
                primaryColor={primary_color}
                gameId={game.id}
                locationKey={locationKey}
              />
            </>
          )}
        </div>
      </Card>
    </CardWrapper>
  );
};

export default GameCard;

import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import microcopy from 'lib/common/lang/en';
import {
  FaqWrap,
  FaqContent,
  FaqTrigger,
  FaqItem,
  AccordionChevron,
} from './styles';

export const FAQ = () => {
  const { faq } = microcopy.landing;

  return (
    <FaqWrap>
      <Accordion.Root type="single" collapsible>
        <FaqItem value="item-1">
          <Accordion.Header>
            <FaqTrigger data-testid="faq1_trigger">
              {' '}
              <span>{faq.items[0].trigger}</span> <AccordionChevron />{' '}
            </FaqTrigger>
          </Accordion.Header>
          <FaqContent>
            <p data-testid="faq1_text1">{faq.items[0].text1}</p>
            <p data-testid="faq1_text2">{faq.items[0].text2}</p>{' '}
          </FaqContent>
        </FaqItem>
        <FaqItem value="item-2">
          <Accordion.Header>
            <FaqTrigger data-testid="faq2_trigger">
              {' '}
              <span>{faq.items[1].trigger}</span>
              <AccordionChevron />
            </FaqTrigger>
          </Accordion.Header>
          <FaqContent>
            <p data-testid="faq2_text1">{faq.items[1].text1}</p>
            <p data-testid="faq2_text2">{faq.items[1].text2}</p>
          </FaqContent>
        </FaqItem>
        <FaqItem value="item-3">
          <Accordion.Header>
            <FaqTrigger data-testid="faq3_trigger">
              {' '}
              <span>{faq.items[2].trigger}</span> <AccordionChevron />
            </FaqTrigger>
          </Accordion.Header>
          <FaqContent>
            <p data-testid="faq3_text1">{faq.items[2].text1}</p>
            <p
              data-testid="faq3_text2"
              dangerouslySetInnerHTML={{
                __html: faq.items[2].text2 as string,
              }}
            />
            <p data-testid="faq3_text3">{faq.items[2].text3}</p>
          </FaqContent>
        </FaqItem>
      </Accordion.Root>
    </FaqWrap>
  );
};

export default FAQ;

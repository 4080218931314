import Link from 'next/link';
import Image from 'next/image';
import { FiArrowRight } from 'react-icons/fi';
import { Wrapper, Content, ScratchNowButton } from './styles';

const ScratchersLandingBanner = () => (
  <Wrapper>
    <Content>
      <Image
        src="/scratchers/scratch-screen.gif"
        alt="Scratch Online to Win"
        width={43}
        height={43}
      />
      <strong>Scratch online for chances to win!</strong>
      <Link href="/auth/signup">
        <ScratchNowButton>
          SCRATCH NOW <FiArrowRight size={15} />
        </ScratchNowButton>
      </Link>
    </Content>
  </Wrapper>
);

export default ScratchersLandingBanner;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import media from 'lib/styles/media';
import { MicroCopy } from 'lib/common/lang/en';
import { PrizeWrap } from './styles';

const coinVariant = {
  isHidden: {
    opacity: 0,
    y: -90,
  },
  isVisible: {
    opacity: 1,
    y: 0,
  },
};

const Coin = styled(motion.img).attrs(() => ({
  variants: coinVariant,
  initial: 'initial',
}))`
  //display: none;
  position: relative;
  z-index: 1;
  max-width: 70px;
  &:nth-of-type(1) {
    left: -17%;
    top: -3px;
  }
  &:nth-of-type(2) {
    left: -14%;
    top: 50px;
  }
  &:nth-of-type(3) {
    left: -2%;
    top: -80px;
  }
  &:nth-of-type(4) {
    right: -2%;
    top: 80px;
  }
  &:nth-of-type(5) {
    right: -8%;
    top: -35px;
  }
  &:nth-of-type(6) {
    right: -12%;
    top: 31px;
  }
  ${media.medium`

    &:nth-of-type(1) {
    left: -17%;
    top: -3px;
  }
  &:nth-of-type(2) {
    left: -14%;
    top: 50px;
  }
  &:nth-of-type(3) {
    left: -11%;
    top: -80px;
  }
  &:nth-of-type(4) {
    right: -7%;
    top: 80px;
  }
  &:nth-of-type(5) {
    right: -8%;
    top: -35px;
  }
  &:nth-of-type(6) {
    right: -12%;
    top: 31px;
  }
  `}
`;

type Prizes = MicroCopy['landing']['prizes'];

interface Props {
  prizeInView?: boolean;
  textContent: Prizes;
}

export const PrizeBar = ({ prizeInView, textContent }: Props) => (
  <PrizeWrap>
    <Coin
      src="/coin_01.webp"
      data-testid="coin_1"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{
        delay: 0.55,
        type: 'spring',
      }}
    />
    <Coin
      src="/coin_02.webp"
      data-testid="coin_2"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{ delay: 0.55, type: 'spring' }}
    />
    <Coin
      src="/coin_03.webp"
      data-testid="coin_3"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{ delay: 0.6, type: 'spring' }}
    />
    <div>
      <h1>{textContent.won_amount}</h1>
      <h3>{textContent.won_text}</h3>
    </div>
    <Coin
      src="/coin_04.webp"
      data-testid="coin_4"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{ delay: 0.65, type: 'spring' }}
    />
    <Coin
      src="/coin_05.webp"
      data-testid="coin_5"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{ delay: 0.7, type: 'spring' }}
    />
    <Coin
      src="/coin_06.webp"
      data-testid="coin_6"
      alt=""
      animate={prizeInView ? 'isVisible' : 'isHidden'}
      transition={{ delay: 0.75, type: 'spring' }}
    />
  </PrizeWrap>
);

export default PrizeBar;

import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useIntersectionObserverRef } from 'rooks';
import Logo from 'components/atoms/icons/logo';
import GameCard from 'components/molecules/game-card';
import { useTrackingClient } from 'lib/tracking';
import { LotteryDetail } from 'lib/types/lottery';
import lotteryBalLeft from 'public/hero_ball_left_v03.webp';
import lotteryBalLRight from 'public/hero_ball_right_v03.webp';
import { useGameLocation } from 'lib/atomic-state';
import { useIpLocation } from 'components/hooks/use-locations';
import microcopy from 'lib/common/lang/en';
import FAQ from './faq';
import PrizeBar from './prize-bar';
import {
  Page,
  Hero,
  HeroCenter,
  StartButton,
  LotteryBallsLeft,
  LotteryBallsRight,
  HalfToneRight,
  HalfToneLeft,
  HalfToneCtaLeft,
  GameCards,
  SectionTitle,
  HowItWorks,
  HiwBlock,
  CtaBar,
} from './styles';

const DynamicMediaBar = dynamic(() => import('./media'), {
  suspense: false,
});

interface Props {
  heroTitle: string;
  heroSubtitle: string;
  lotteries: LotteryDetail[];
}

export const LandingPage = ({ heroTitle, heroSubtitle, lotteries }: Props) => {
  const trackingClient = useTrackingClient();
  const [prizeInView, setPrizeInView] = useState(false);

  const { gameLocation } = useGameLocation();
  const { ipLocation } = useIpLocation();

  const locationKey = gameLocation?.value || ipLocation?.IPstate;

  const inViewCallback = (
    entries: { isIntersecting: boolean | ((prevState: boolean) => boolean) }[],
  ) => {
    if (entries && entries[0]) {
      setPrizeInView(entries[0].isIntersecting);
    }
  };

  const trackGetStarted = (isHero: boolean) => {
    trackingClient.trackEventForPrimaryTrackers('Get Started Clicked', {
      source: isHero ? 'hero' : 'footer',
    });
  };

  const [faqRef] = useIntersectionObserverRef(inViewCallback);
  const { hero, hiw, faq, cta, prizes } = microcopy.landing;

  return (
    <Page>
      <Hero>
        <Logo isLight />
        <HalfToneLeft>
          <Image
            className="halfToneLeft"
            src="/halftone_2022-06-27_v02_left.webp"
            width="640"
            height="450"
            quality={100}
            alt="Lottery Ball"
            priority
          />
        </HalfToneLeft>
        <HalfToneRight>
          <Image
            src="/halftone_2022-06-27_v02_right.webp"
            width="640"
            height="450"
            quality={100}
            alt="Lottery Ball"
            priority
          />
        </HalfToneRight>

        <HeroCenter>
          <h1 data-testid="home_main_title_text">{heroTitle || hero.title}</h1>
          {heroSubtitle && (
            <h2 dangerouslySetInnerHTML={{ __html: heroSubtitle }} />
          )}
          <Link href="/auth/signup" passHref legacyBehavior>
            <StartButton
              data-testid="get_started_top"
              onClick={() => trackGetStarted(true)}
            >
              {hero.btn_label}
            </StartButton>
          </Link>

          <LotteryBallsLeft>
            <Image src={lotteryBalLeft} alt="Lottery Ball" priority />
          </LotteryBallsLeft>
          <LotteryBallsRight>
            {' '}
            <Image src={lotteryBalLRight} alt="Lottery Ball" priority />
          </LotteryBallsRight>
        </HeroCenter>
      </Hero>
      <GameCards>
        {lotteries.map((item) => (
          <GameCard game={item} key={item.id} locationKey={locationKey} />
        ))}
      </GameCards>
      <SectionTitle>{hiw.title}</SectionTitle>
      <HowItWorks>
        <HiwBlock>
          <img
            src="icons/web-app_icon_how-it-works_tickets.svg"
            height={80}
            width={80}
            alt="Scan"
          />
          <p>{hiw.item1}</p>
        </HiwBlock>
        <HiwBlock>
          <img
            src="/icons/web-app_icon_how-it-works_results.svg"
            height={80}
            width={80}
            alt="Results"
          />
          <p>{hiw.item2}</p>
        </HiwBlock>
        <HiwBlock>
          <img
            src="/icons/web-app_icon_how-it-works_prizes.svg"
            height={80}
            width={80}
            alt="Transfer"
          />
          <p>{hiw.item3}</p>
        </HiwBlock>
      </HowItWorks>
      <PrizeBar prizeInView={prizeInView} textContent={prizes} />
      <SectionTitle ref={faqRef}>{faq.title}</SectionTitle>
      <FAQ />

      <DynamicMediaBar />

      <CtaBar>
        <HalfToneCtaLeft>
          {' '}
          <Image
            src="/halftone_ready-to-play_v02_left.webp"
            alt=""
            width="640"
            height="177"
          />
        </HalfToneCtaLeft>
        <HalfToneRight>
          <Image
            src="/halftone_ready-to-play_v02_right.webp"
            alt=""
            width="640"
            height="177"
          />
        </HalfToneRight>
        <div>
          <h1>{cta.title}</h1>
          <Link href="/auth/signup" passHref legacyBehavior>
            <StartButton
              data-testid="get_started_bottom"
              onClick={() => trackGetStarted(false)}
            >
              {cta.btn_label}
            </StartButton>
          </Link>
        </div>
      </CtaBar>
    </Page>
  );
};

export default LandingPage;

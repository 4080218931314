import React, { useMemo } from 'react';
import { LotteryDetail } from 'lib/types/lottery';
import useGameTimes from 'components/hooks/use-game-times';
import { Timer } from './styles';

interface Props {
  game: LotteryDetail;
  isTransparent?: boolean;
}

export const CountDown = ({ game, isTransparent = false }: Props) => {
  const { countDown } = useGameTimes(game);

  const { days, hours, minutes, seconds } = countDown;

  const formatHours = () => {
    if (days && days > 0) {
      return Number(days * 24) + Number(hours);
    }
    return hours;
  };

  const hoursUnit = useMemo(() => formatHours(), [minutes]);

  return (
    <Timer isTransparent={isTransparent}>
      <span data-testid={`countdown_time_${game.id}`}>
        {String(hoursUnit).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
        {String(seconds).padStart(2, '0')}
      </span>
    </Timer>
  );
};

export default CountDown;

import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Timer = styled.div<{ isTransparent?: boolean }>`
  @import url('https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap');

  position: relative;
  width: 88px;
  height: 26px;
  border-radius: 8px;
  padding: 6px 8px;
  background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : theme.colors.bg};

  > span {
    position: absolute;
    left: 8px;
    font-family: 'Oxygen Mono', monospace;
    font-size: ${rem(13)};
    letter-spacing: 1px;
  }
`;

import React, { ReactNode, useState, useCallback, CSSProperties } from 'react';
import { FiX } from 'react-icons/fi';
import { AnimatePresence } from 'framer-motion';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Text from 'components/atoms/text';
import { useIntersectionObserverRef } from 'rooks';
import {
  Header,
  Overlay,
  ModalContent,
  ModalContainer,
  CloseButton,
  StickyHeader,
} from './styles';

export const modalVariant = {
  initial: { opacity: 0, transition: { type: 'spring', damping: 600 } },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

export const containerVariant = {
  initial: { top: '-50%', transition: { type: 'spring', damping: 600 } },
  isOpen: { top: '50%' },
  exit: { top: '-50%' },
};

type Props = {
  children: ReactNode;
  canDismiss: boolean;
  styles?: CSSProperties;
  title?: string;
  onClose?: () => void;
};

const Modal = NiceModal.create(
  ({ children, canDismiss = true, styles, title, onClose }: Props) => {
    const modal = useModal();

    const onAnimationComplete = (variant: string) =>
      variant === 'exit' && modal.remove();

    const onModalClose = () => {
      onClose?.();
      modal.hide();
    };

    // Use intersection observer to show sticky header
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [contentContainer, setContentContainer] = useState<Element>();
    const setContentContainerRef = useCallback(
      (el: any) => setContentContainer(el),
      [],
    );

    const [contentRef] = useIntersectionObserverRef(
      (entries: any) => {
        entries.forEach((e: any) => setShowStickyHeader(!e.isIntersecting));
      },
      {
        root: contentContainer,
      },
    );

    return (
      <AnimatePresence>
        {modal.visible && (
          <Overlay
            initial="initial"
            animate="isOpen"
            exit="exit"
            variants={modalVariant}
            onAnimationComplete={onAnimationComplete}
            onClick={canDismiss ? onModalClose : undefined}
          >
            <ModalContainer
              initial="initial"
              animate="isOpen"
              exit="exit"
              variants={containerVariant}
              style={styles}
              data-testid="modal_container"
              onClick={(e) => e.stopPropagation()}
            >
              {(canDismiss || title) && (
                <StickyHeader
                  animate={{ top: showStickyHeader ? '0' : '-50%' }}
                >
                  {canDismiss && (
                    <CloseButton
                      onClick={onModalClose}
                      data-testid="modal_sticky_header_dismiss"
                    >
                      <FiX size="20px" />
                    </CloseButton>
                  )}
                  {title && (
                    <Text
                      as="h2"
                      variant="heading"
                      textAlign="center"
                      data-testid="modal_title"
                    >
                      {title}
                    </Text>
                  )}
                </StickyHeader>
              )}
              <ModalContent ref={setContentContainerRef}>
                {canDismiss && (
                  <Header>
                    <CloseButton
                      onClick={onModalClose}
                      data-testid="modal_dismiss"
                    >
                      <FiX size="20px" />
                    </CloseButton>
                  </Header>
                )}
                <div ref={contentRef} />
                {children}
              </ModalContent>
            </ModalContainer>
          </Overlay>
        )}
      </AnimatePresence>
    );
  },
);

export default Modal;

import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';
import media from 'lib/styles/media';

const { yellow, white } = theme.colors;

export const Wrapper = styled.div`
  background-color: ${yellow};
  padding: 15px 0;
  width: 100%;

  ${media.medium`
    padding: 10px 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-items: center;
  font-size: ${rem(20)};

  ${media.medium`
    flex-direction: row;
    gap: 15px;
    justify-content: center;
  `}
`;

export const ScratchNowButton = styled.button`
  border-radius: 12px;
  background-color: ${white};
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 0.07);
  display: flex;
  align-items: center;
  font-size: ${rem(13)};
  font-weight: 600;
  gap: 6px;
  padding: 6px 16px;
`;

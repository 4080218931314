import React, { ReactNode, CSSProperties } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button, ButtonLight } from 'components/atoms/buttons';
import Modal from 'components/atoms/modal';
import { PromptWrap, ButtonRow, IconTop } from 'components/atoms/modal/styles';
import useLockBodyScroll from 'components/hooks/use-body-lock-scroll';

interface CustomPromptProps {
  children?: ReactNode;
  header?: string;
  message?: string;
  cancelText?: string;
  proceedText?: string;
  withIcon?: 'check';
  onCancel?: () => void;
  onProceed?: () => void;
}

interface UseCustomModalProps extends CustomPromptProps {
  canDismiss?: boolean;
  styles?: CSSProperties;
  title?: string;
  onClose?: () => void;
}

const iconPathMap = {
  check: 'ic_trust_official.svg',
};

function CustomPrompt({
  children,
  header,
  message,
  cancelText,
  proceedText,
  onCancel,
  onProceed,
  withIcon,
}: CustomPromptProps) {
  useLockBodyScroll();
  return (
    <PromptWrap>
      {children}
      {withIcon && (
        <IconTop src={`/icons/${iconPathMap[withIcon]}`} alt={`${withIcon}`} />
      )}
      {header && <h2 data-testid="modal_header_text">{header}</h2>}
      {message && <p data-testid="modal_message_text">{message}</p>}
      {(onCancel || onProceed) && (
        <ButtonRow>
          {onCancel && (
            <ButtonLight onClick={onCancel} data-testid="modal_cancel">
              {cancelText || 'Cancel'}
            </ButtonLight>
          )}
          {onProceed && (
            <Button onClick={onProceed} data-testid="modal_ok">
              {proceedText || 'OK'}
            </Button>
          )}
        </ButtonRow>
      )}
    </PromptWrap>
  );
}

export default function useCustomModal() {
  const modal = useModal(Modal);

  const customModal = {
    ...modal,
    show: (props: UseCustomModalProps) => {
      const {
        canDismiss = true,
        styles,
        title,
        onClose,
        ...promptProps
      } = props;

      return modal.show({
        children: <CustomPrompt {...promptProps} />,
        canDismiss,
        styles,
        title,
        onClose,
      });
    },
  };

  return customModal;
}

import { format, fromUnixTime } from 'date-fns';

// eslint-disable-next-line no-shadow
export enum DateFormat {
  dateShort = 'EEEE, p',
  dateLong = 'E, MMM d, y, p',
  time = 'p',
}

interface Props {
  timestamp: number;
  label?: string;
  dateFormat: DateFormat;
  dataTestId?: string;
}

export const FormatTime = ({
  timestamp,
  label,
  dateFormat = DateFormat.dateShort,
  dataTestId = '',
}: Props) => (
  <span data-testid={dataTestId}>
    {label} {format(fromUnixTime(timestamp), dateFormat)}
  </span>
);

export default FormatTime;

import styled, { css } from 'styled-components';
import { rem, darken, transparentize } from 'polished';
import media from 'lib/styles/media';
import theme from 'lib/styles/theme';
import { Button } from 'components/atoms/buttons';

export const CardWrapper = styled.div``;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 34px 32px 28px;
  border: 1px solid ${theme.colors.border};
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgb(0 0 0 / 0.07);
  text-align: center;
  transition: all 0.2s ease-in;
  display: grid;
  grid-template-rows: 1fr auto;

  h2 {
    margin: 0 0 6px;
    font-family: ${theme.font.display};
    font-size: ${rem(38)};
    color: ${({ color }) => color};
  }
`;

export const MetaRow = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.gray_pale};
  border: 1px solid ${theme.colors.border};
  border-top: none;
  border-radius: 0 0 3px 3px;
`;

export const JackPocketSum = styled.p`
  font-family: ${theme.font.display};
  font-size: ${rem(38)};
  color: ${theme.colors.charcoal};
  margin: 0 0 12px;
`;

export const MetaText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > span {
    display: block;
    font-size: ${rem(18)};
    margin: 6px 0;
  }

  > strong {
    margin-left: 6px;
    line-height: normal;
  }
`;

export const CutoffTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  gap: 10px;

  ${media.medium`
    gap: 40px;
  `}
`;

export const PlayButton = styled(Button)`
  margin-top: ${rem(20)};
  height: 44px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  transition: all 0.2s ease-in;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
      border-color: ${color};
      box-shadow: 0 4px 8px ${transparentize(0.85, color)},
        inset 0 -4px 0 rgb(0 0 0 / 0.15);

      &:hover {
        background-color: ${darken(0.1, color)};
      }
    `}
`;

export const ExpiredOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${transparentize(0.2, theme.colors.white)};
  backdrop-filter: blur(2px);

  > h2 {
    font-family: ${theme.font.display};
    color: ${theme.colors.gray_medium};
  }
`;

export const GameHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  sup {
    font-size: ${rem(18)};
    top: -1.5rem;
  }
`;

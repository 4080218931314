import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { LotteryDetail } from 'lib/types/lottery';
import { getJackpocketTimesForLottery } from 'components/hooks/use-game-times';
import CountDown from 'components/atoms/countdown';
import { DateFormat } from 'components/atoms/format-time';
import { CutoffTime, MetaText } from './styles';

const FormatTime = dynamic(() => import('components/atoms/format-time'), {
  ssr: false,
});

interface Props {
  game: LotteryDetail;
  children: ReactNode;
}

export const LiveGameDetails = ({ game, children }: Props) => {
  const { nextDrawingTime: drawingTime, nextCutoffTime } =
    getJackpocketTimesForLottery(game);

  return (
    <>
      <MetaText>
        <FormatTime
          timestamp={drawingTime}
          dateFormat={DateFormat.dateShort}
          label="Drawing"
          dataTestId="lottery_drawing_time"
        />
      </MetaText>

      {children}
      <CutoffTime>
        <span>
          Cut-off Time:{' '}
          <strong>
            <FormatTime
              timestamp={nextCutoffTime}
              dateFormat={DateFormat.time}
              dataTestId={`cutoff_time_${game.id}`}
            />
          </strong>
        </span>
        <CountDown game={game} />
      </CutoffTime>
    </>
  );
};

export default LiveGameDetails;
